<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header :headerName="userName + '动物免疫记录'" @selectJob="selectJob" />

    <div class="content_box w_full flex_row_around pt_10">
      <div class="w_5_p ph_10">
        <el-select 
          class="w_full_i" 
          v-model="queryParams.animalId" size="small" 
          placeholder="动物种类" filterable clearable 
          @clear="clearAnimal"
        >
          <el-option
            v-for="(item, index) in animalList" :key="index"
            :label="item.name"
            :value="item.id"
            @click.native="selectAnimal(item)">
          </el-option>
        </el-select>
      </div>

      <div class="w_65_p">
        <el-button type="success" size="small" @click="exportMuHuMianYiData">导出数据</el-button> 
      </div>

      <div class="w_20_p text_right ph_10">
        <el-button type="primary" size="small" @click="filterRecordsYiCountRes()">搜索</el-button>
        <el-button type="warning" size="small" @click="reset()">重置</el-button>
      </div>
    </div>

    <div class="content_box w_full h_full-100 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_full h_full bgs_full ph_10 relative"> 
        <el-table :data="recordYiList" border style="width: 100%; height: 100%;" max-height="900">
          <el-table-column type="index" label="序号" width="60" align="center" />
          <el-table-column prop="userNickName" label="畜主名称" align="center" />
          <el-table-column prop="" label="免疫日期" >
            <template slot-scope="scope">
              {{ formatDate(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="animalName" label="动物种类" align="center" />
          <el-table-column prop="animalCount" label="存栏数" align="center" />
          <el-table-column prop="vaccinumEffect" label="免疫病种" align="left" />
          <el-table-column prop="fangYiCount" label="免疫数" align="center" />
          <el-table-column prop="vaccinumName" label="免疫疫苗" align="left" />
          <el-table-column prop="maker" label="疫苗厂家" align="left" />
          <el-table-column prop="batchNumber" label="疫苗批号" />
          <el-table-column prop="specs" label="疫苗用量" align="center" />
          <el-table-column prop="antiepidemicPersonName" label="防疫员" align="center" />
          <el-table-column prop="" label="防疫员签字" align="center">
            <template slot-scope="scope">
              <div class="w_50 h_50 relative">
                <div class="w_50 h_50 absolute top_0 left_0 z_999">
                  <el-image
                    class="full"
                    :src="filePath + scope.row.fyySignPicture2"
                    :preview-src-list="srcList"
                    :initial-index="0"
                    @click="handleClickFyyImg(scope.$index)"
                  />
                </div>

                <!-- 防疫员头像 -->
                <div class="w_50 h_50 absolute top_0 left_0">
                  <el-image
                    class="full"
                    :src="filePath + scope.row.fyySignPicture1"
                    :preview-src-list="srcList"
                  />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="畜主签字" align="center">
            <template slot-scope="scope">
              <div class="w_50 h_50 relative">
                <div class="w_50 h_50 absolute top_0 left_0 z_999">
                  <el-image
                    class="full"
                    :src="filePath + scope.row.signPicture2"
                    :preview-src-list="srcList"
                    :initial-index="0"
                    @click="handleClickXuZhuImg(scope.$index)"
                  />
                </div>

                <div class="w_50 h_50 absolute top_0 left_0">
                  <el-image
                    class="full"
                    :src="filePath + scope.row.signPicture1"
                    :preview-src-list="srcList"
                  />
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="w_full-20 bgc_fff text_right absolute bottom_0 z_999">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.page"
            :page-sizes="[100, 200, 500, 1000]"
            :page-size="queryParams.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import * as Excel from 'exceljs';
  import { saveAs } from 'file-saver';

  import defMixin from '@/mixins/def.js' 
  import componentsMixin from '@/mixins/components.js'
  import geoJsonMapMixin from '@/mixins/geoJson-map.js'
  import imgMixin from '@/mixins/def-img.js'

  import { filePath } from "@/const"

  import { filterRecordsYiCount } from '@/api/fang-yi/fang-yi.js'
  import { getAnimalList } from '@/api/animal/index.js'

  export default {
    name: 'Search',
    mixins: [defMixin, componentsMixin, geoJsonMapMixin, imgMixin],
    data() {
      return {
        filePath: filePath,
        total: 0,
        activeRowIndex: null,
        antiepidemicPersonList: [],
        
        queryParams: {
          userId: null,
          jobId: null,
          animalId: null,
          page: 1,
          size: 200
        },

        recordYiList: [],

        activeIndex: 0,

        animalList: [],

        activeYiMiaoType: '',
        springYiMiaoList: [],
        autumnYiMiaoList: [],
        yiMiaoList: [],

        yiMiaoName: '',
        userName: '',
        animalName: '',

        srcList: []
      }
    },

    mounted() {
      let userId = this.$route.params.userId
      let userName = this.$route.params.userName
      let animalId = this.$route.params.animalId

      this.queryParams.userId = userId
      this.userName = userName

      this.filterRecordsYiCountRes()

      this.getAnimalList()
    },

    methods: {
      selectJob(jobId) {
        this.queryParams.jobId = jobId;
        this.queryParams.userId = this.$route.params.userId

        this.filterRecordsYiCountRes(this.queryParams)
      },

      reset() {
        this.queryParams.animalId = null
        this.queryParams.page = 1
        this.queryParams.size = 200

        this.filterRecordsYiCountRes(this.queryParams)
      },

      // 获取动物列表
      async getAnimalList() {
        let res = await getAnimalList()
        try {
          if (res.code == 1000) {
            this.animalList = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 牧户的 免疫台账信息
      async filterRecordsYiCountRes() {
        const loading = this.$loading({
          lock: true,
          text: '数据加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        setTimeout(() => {
          loading.close();
        }, 1000);

        let params = {
          userId: this.queryParams.userId,
          jobId: this.queryParams.jobId,
          animalId: this.queryParams.animalId,
          page: 1,
          size: 200
        }

        let res = await filterRecordsYiCount(params)
        try {
          if (res.code == 1000) {
            this.recordYiList = res.data.list
            this.total = res.data.pagination.total

            this.handleClickFyyImg(0)
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 跳转页面
      handleSizeChange(val) {
        this.queryParams.size = val

        this.filterRecordsYiCountRes(this.queryParams)
      },

      // 更新 分页条数
      handleCurrentChange(val) {
        this.queryParams.page = val

        this.filterRecordsYiCountRes(this.queryParams)
      },

      clearAnimal() {
        this.queryParams.animalId = null
        this.filterRecordsYiCountRes(this.queryParams)
      },

      selectAnimal(item) {
        this.queryParams.animalId = item.id
        this.filterRecordsYiCountRes(this.queryParams)
      },

      // 点击防疫员签到图片
      handleClickFyyImg(index) {
        this.srcList = []
        if (this.recordYiList && this.recordYiList.length) {
          let img1 = this.filePath + this.recordYiList[index].fyySignPicture1
          let img2 = this.filePath + this.recordYiList[index].fyySignPicture2

          let list = [img1, img2]

          this.mergeImgs(list).then((base64) => {
            this.srcList = [base64, img1, img2]
          });
        }
      },

      // 点击畜主签到图片
      handleClickXuZhuImg(index) {
        this.srcList = []
        if (this.recordYiList && this.recordYiList.length) {
          let img1 = this.filePath + this.recordYiList[index].signPicture1
          let img2 = this.filePath + this.recordYiList[index].signPicture2
          let list = [img1, img2]

          this.mergeImgs(list).then((base64) => {
            this.srcList = [base64, img1, img2]
          });
        }
      },

      mergeImgs(list, cwidth=480, cheight=640) {
        return new Promise((resolve, reject) => {
          const baseList = [];
          // 创建 canvas 节点并初始化
          const canvas = document.createElement('canvas');

          canvas.width = cwidth + 10;
          canvas.height = cheight

          const context = canvas.getContext('2d');
          list.map((item, index) => {
            const img = new Image();
            // 跨域
            img.crossOrigin = 'Anonymous';
            img.src = item + `?timestamp= ${Date.now()}`;
            img['index'] = index + 1
            img.onload = () => {
              // context.drawImage(img, 0, 0, cwidth * index, cheight)
              context.drawImage(img, 0, 0, cwidth, cheight)
              context.globalAlpha = 1
              context.globalCompositeOperation = "darken"

              const base64 = canvas.toDataURL('image/png');

              baseList.push(base64);

              if (baseList[list.length - 1]) {
                // 返回新的图片
                resolve(baseList[list.length - 1]);
              }
            };
          });
        });
      },


      // 跳转到牧户详情页
      handleClickMuhu(id) {
        this.$router.push(`/muHuPage/${null}/${null}/${null}/${id}`)
      },

      // 按牧户 导出 免疫台账 （分页 生成 Excel）
      async exportMuHuMianYiData() {
        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('我的工作表');

        worksheet.columns = [
          { header: '畜主名称', key: 'userNickName', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '免疫日期', key: 'createTime', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '动物种类', key: 'animalName', width: 10, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '存栏数', key: 'animalCount', width: 10, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '免疫病种', key: 'vaccinumEffect', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '免疫数', key: 'fangYiCount', width: 10, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '免疫疫苗', key: 'vaccinumName', width: 50, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '疫苗厂家', key: 'maker', width: 40, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '疫苗批号', key: 'batchNumber', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '疫苗用量', key: 'specs', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '防疫员签字', key: 'antiepidemicPersonName', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '畜主签字', key: 'userNickName2', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } }
        ];

        let page = Math.ceil(this.total / this.queryParams.size)

        for (let i = 0; i < page; i++) {
          let dataList = []

          let params = {
            userId: this.queryParams.userId,
            jobId: this.queryParams.jobId,
            animalId: this.queryParams.animalId,
            page: i + 1,
            size: this.queryParams.size
          }

          let res = await filterRecordsYiCount(params)
          try {
            if (res.code == 1000) {
              dataList = res.data.list 

              for (let i in dataList) {
                dataList[i]['userNickName2'] = dataList[i].userNickName
                worksheet.addRow(dataList[i]);
              }
            }
          } catch (err) {
            console.log('获取牧户免疫记录失败 : ', err);
          }
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, `${this.userName}-免疫台账明细.xlsx`);
      },
    }
  }
</script>

<style>
.el-pagination__total, .el-pagination__jump { color: orange !important; }
.el-image-viewer__mask { background-color: #fff !important; }
/* .el-image-viewer__img {
  transform-origin: center center !important;
  transform: scale(1) rotate(90deg) !important; 
} */
</style>
